import React from 'react';

function ContactPage() {
  return (
    <>
      <h1>
        Lets Chat Soon! 
      </h1>
    </>
  );
}

export default ContactPage;